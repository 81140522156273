.inputBase {
  width: 100% !important;
  min-width: 250px;
  height: 60px !important;
  background-color: #f1f3f9 !important;
  font-size: 18px !important;
  border: none !important;
  border-radius: 4px 4px 0 0 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #c9ced6 !important;
  transition: none !important;
}

.inputBase:focus {
  border-radius: 4px !important;
  box-shadow: none !important;
  border-color: #2c37b2 !important;
  border-style: solid !important;
  border-width: 2px !important;
  transition: none !important;
}

.inputFlushed {
  width: 100% !important;
  min-width: 250px;
  height: 60px;
  background-color: transparent !important;
  font-size: 18px !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #e1e4e8 !important;
  transition: none !important;
}

.inputFlushed:focus {
  box-shadow: none !important;
  border: none !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #2c37b2 !important;
  transition: none !important;
}

.container {
  z-index: 3;
}
